import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import { Image } from '../../components/Image'
import Page from '../../components/page'

import { find } from 'lodash-es'

import SectionStack from '../../components/section-stack'

export const PageTemplate = ({ page, siteTitle, globals, useTransitions = true, ...props }) => {
    const sections = page.sections || []

    const navbarRetainer = !find(
        sections,
        (section) =>
            section.type == 'ContentfulSectionVideo' ||
            (section.type == 'ContentfulSectionEntries' && section.alternateTextSwitch) ||
            (section.type == 'ContentfulSectionImage' && section.variant == 'Fullwidth Image')
    )

    return (
        <Page
            location={props.location}
            schema={page.variant}
            title={page.title}
            siteTitle={siteTitle}
            useTransitions={useTransitions}
            navbarRetainer={navbarRetainer}
        >
            <SectionStack
                sections={sections}
                margin
                marginBottom={'small'}
                globals={globals}
                withDividers={page.handle == 'about'}
            />
        </Page>
    )
}

export const PageGatsbyTemplate = (props) => {
    const page = get(props, 'data.contentfulPage')
    const siteTitle = get(props, 'data.site.siteMetadata.title')

    return <PageTemplate {...props} {...{ page, siteTitle }} />
}

export default PageGatsbyTemplate

export const pageQuery = graphql`
    query PageByHandle($handle: String!) {
        site {
            siteMetadata {
                title
            }
        }

        contentfulPage(handle: { eq: $handle }) {
            ...pageFragment
        }
    }
`
